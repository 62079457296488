@media only screen and (min-width: 320px) {

    .header-container {
        padding-left: 0;
        padding-right: 0;
    }

    label {
        font-size: 14px;
    }

    label.semi-bold {
        font-size: 12px;
    }

    .row.mt-5 {
        margin-top: 0 !important;
    }

    .table-tall {
        height: auto;
        /*    height: 18rem;*/
    }

    div.col-sm-12.ml-5 {
        margin-left: 0 !important;
        margin-top: 32px !important;
    }

    div.col-sm-6.pl-5 {
        padding-left: 15px !important;
    }

    div.col.pl-5 {
        padding-left: 15px !important;
    }
}

@media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }

    .row.mt-5 {
        margin-top: 3rem !important;
    }

    .mobile-break {
        display: none;
    }

    div.col.ml-5 {
        margin-left: 3rem !important;
    }

    div.col-sm-6.pl-5 {
        padding-left: 3rem !important;
    }

    div.col.pl-5 {
        padding-left: 3rem !important;
    }

    label {
        font-size: 16px;
    }

    label.semi-bold {
        font-size: 16px;
    }

    .print-footer {
        display: none;
    }
}

@media print {
    .print {
        display: block !important;
        margin: 20px;
    }

    .no-print {
        display: none
    }

    .break-before {
        page-break-before: always;
    }

    .print-footer {
        position: fixed;
        bottom: 5px;
        left: 10px;
    }
}

html {
    margin-left: 0;
}

body {
    font-size: 1rem;
}

a {
    color: #0366d6;
}

th {
    font-weight: 500;
}

label {
    font-weight: 500;
}

.form-row-spacer {
    margin-bottom: 20px;
}

code {
    color: #E01A76;
}

sup {
    color: red;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.not-found {
    margin-top: 10em;
}

.logo img {
    height: 3em;
}

.semi-bold {
    font-weight: 500;
}

.center-loader {
    flex: auto;
    align-items: center;
    justify-content: center;
}

.required {
    color: red;
    padding-right: 0.2rem;
}

.form-area {
    min-height: 100px;
}

.hidden {
    display: none;
}

.ssn-input-btn {
    margin-top: 2rem;
}

.new-member {
    display: none;
}

.no-top-border {
    border-top-style: none !important;
}

.brand-color-red {
    color: #cf2a27;
}

.brand-color-darkgray {
    color: #373737
}

.brand-color-lightgray {
    color: #696969;
}

#review hr {
    margin-top: 0;
}

.spacer-1 {
    height: 20px;
}

.error {
    color: red;
}

.acknowledge {
    border: solid 1px lightgray;
    margin-left: 1rem;
}

.confirmAgree {
    font-weight: 600;
    color: #696969;
    font-size: 16px;
}

.list-tall {
    padding-left: 1rem;
}

.list-tall li {
    padding-bottom: 1rem;
}

/* Footer */
.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

#pe-footer {
    font-size: 1em;
    padding: 30px 0;
    color: lightgray;
    background: #373737;
    line-height: 35px;
}

.breadcrumb-divider-arrow {
    --bs-breadcrumb-divider: '>';
}

.breadcrumb {
    background-color: transparent;
    margin: 0;
    font-size: calc(8px + 1vw);
}

.breadcrumb-item.active {
    font-weight: bold;
    color: #cf2a27;
}

.content {
    min-height: calc(100vh - 295px);
}

.footer {
    height: 50px;
    clear: both;
    position: relative;
}

.bill-select {
    padding-left: 20px;
}

.card-name {
    height: 100px;
    padding-left: 36px;
    font-size: 22px;
    font-weight: 500;
}

.background-light {
    background-color: #dcdcdc50;
    border-radius: 10px;
    margin-top: 8px;
}

.background-light-header {
    background-color: #b0c4de60;
    border-radius: 10px 10px 0 0;
}

/* icons and tooltip from accuweather */
.icon-tooltip {
    border: 1px solid rgba(27 110 194 / 70%);
    border-radius: 50%;
    color: rgba(27 110 194 / 70%);;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;
    font-weight: 800;
    font-family: serif;
}

.icon-tooltip.is-active {
    background-color: #f05514;
    border: 1px solid #f05514;
    color: #fff;
}

.icon-tooltip:before {
    content: "i";
    font-style: italic;
    font-size: 16px;
    line-height: 18px;
}

.tooltip-overlay {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    display: none;
    padding: 12px 12px 24px 1rem;
    position: absolute;
    right: -12px;
    top: -12px;
    width: calc(100vw - 24px);
    z-index: 6;
}

.tooltip-overlay .tooltip-header {
    display: flex;
    margin-bottom: 8px;
}

.tooltip-overlay .tooltip-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    padding-right: 32px;
    text-align: left;
    text-transform: none;
}

.tooltip-button {
    border: 0;
    background: inherit;
    font-size: 0;
    color: white;
}


.tab-contents {
    border: 1px solid lightgray;
    padding: 26px 32px 32px 12px;
}

/* over-riding bootstrap nav button */
.nav-tabs {
    border-bottom: 0;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid lightgray;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #212529;
    background-color: white;
    border-color: #c7cbce #c7cbce white #c7cbce;
    /*font-weight: 500;*/
}

/* read only fields */
.form-control[readonly]:not([value=""]) {
    background-color: #e8f0fe;
}

.confirmation-number {
    padding: 0.5rem !important;
    font-size: 16px;
}
.confirmation-container {
    width: 11rem;
}

.confirmation-disenroll-transfer {
    border: 0;
    font-weight: 600; 
    background-color: white !important; 
    width: 10rem;
    display: inline;
}

.hidden-if-empty:empty {
    display: none;
}

.option-regular {
    font-size: 1rem !important;
}
.option-sm {
    font-size: 0.8rem !important;
}


